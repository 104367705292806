import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import InfoCardImage, {
  InfoCardImageProps,
} from "../../components/infoCardImage/infoCardImage";
import DividerSectionHeader from "../../components/sectionHeader/dividerSectionHeader/dividerSectionHeader";

const churchInfo: InfoCardImageProps = {
  type: "right",
  title: "Ceremonia Religiosa",
  description: "Capilla del sagrario en la catedral metropolitana",
  timeDescription: "6:00pm",
  locationDescription: "Juan Zuazua 1100 Sur, Centro, 64000 Monterrey, N.L.",
  link: "https://goo.gl/maps/ZufDdeu5Viuw9dw5A",
  imageSrc: "/pictures/details/007.jpg",
};

const civilInfo: InfoCardImageProps = {
  type: "left",
  title: "Civil",
  description: "Zanq",
  timeDescription: "7:30pm",
  locationDescription:
    "Jesús Reyes Ferreyra Esq con Av. Fundadores, Zona Valle Oriente.",
  link: "https://goo.gl/maps/4Eh7ZvEZxtVmwJUa9",
  imageSrc: "/pictures/details/005.jpg",
};

const receptionInfo: InfoCardImageProps = {
  type: "right",
  title: "Recepción",
  description: "Zanq",
  timeDescription: "8:00pm",
  locationDescription:
    "Jesús Reyes Ferreyra Esq con Av. Fundadores, Zona Valle Oriente.",
  link: "https://goo.gl/maps/4Eh7ZvEZxtVmwJUa9",
  imageSrc: "/pictures/details/003.png",
};

export default function EventDetails() {
  return (
    <Stack id="event-details" w={"full"} p={"20pt"}>
      <DividerSectionHeader title="Detalles del Evento"></DividerSectionHeader>
      <Stack spacing={10} p={{ base: undefined, md: 10 }}>
        <InfoCardImage {...churchInfo}></InfoCardImage>
        <InfoCardImage {...civilInfo}></InfoCardImage>
        <InfoCardImage {...receptionInfo}></InfoCardImage>
        <Stack
          direction={{ base: "column", md: "row" }}
          p={2}
          flex={1}
          align={"center"}
          justify={"center"}
          backgroundColor={useColorModeValue("white", "white")}
          borderWidth={"3px"}
          borderColor={useColorModeValue("blackAlpha.600", "gray.600")}
          pos={"relative"}
        >
          <Flex flex={1} m={8} align={"center"} justify={"center"}>
            <Stack spacing={2} w={"full"} maxW={"lg"}>
              <Heading
                fontSize={{ base: "3xl", md: "4xl", lg: "5xl" }}
                textAlign={"center"}
              >
                <Text
                  fontWeight={400}
                  fontSize={"4xl"}
                  color={useColorModeValue("gray.800", "Black")}
                  as={"span"}
                >
                  Código de vestimenta
                </Text>
                <br />
              </Heading>
              <Box textAlign={"center"}>
                <Text
                  fontSize={"2xl"}
                  fontWeight={800}
                  color={useColorModeValue("gray.600", "Black")}
                >
                  Etiqueta
                </Text>
              </Box>
            </Stack>
          </Flex>
        </Stack>
      </Stack>
    </Stack>
  );
}
