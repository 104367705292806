import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  fonts: {
    heading: `'Birthstone', handwriting`,
    body: `'Charm', handwriting`,
  },
  initialColorMode: "light",
  useSystemColorMode: false,
});

export default theme;
