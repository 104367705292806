import { Box, Link, Stack, Text, useColorModeValue } from "@chakra-ui/react";
import SectionHeader from "../../components/sectionHeader/sectionHeader";

import { EmailIcon } from "@chakra-ui/icons";

export default function GiftTable() {
  return (
    <Stack
      id="gift-table"
      w={"full"}
      p={"20pt"}
      backgroundImage={useColorModeValue(
        "linear-gradient(160deg, gray 50%, white 50%)",
        "linear-gradient(160deg, #475776 50%, #1a202c 50%)"
      )}
      spacing={-4}
    >
      <SectionHeader title="Mesa de Regalos"></SectionHeader>
      <Stack spacing={8} py={12} px={6} align={"center"} justify={"center"}>
        <Box
          bg={useColorModeValue("white", "white")}
          boxShadow={"dark-lg"}
          p={8}
          w={{ base: "80vw", md: "40vw" }}
          textAlign={"center"}
        >
          <Stack spacing={3} align={"center"} justify={"center"}>
            <Text
              fontSize={"lg"}
              fontWeight={400}
              color={useColorModeValue("gray.600", "Black")}
            >
              Liverpool
            </Text>
            <Text
              fontSize={"lg"}
              fontWeight={400}
              color={useColorModeValue("gray.600", "Black")}
            >
              BODA ITZEL Y ALEJANDRO
            </Text>
            <Text
              fontSize={"lg"}
              fontWeight={400}
              color={useColorModeValue("gray.600", "Black")}
            >
              Evento: 50899047
            </Text>
            <Text
              fontSize={"lg"}
              fontWeight={400}
              color={useColorModeValue("gray.600", "Black")}
              as={Link}
              href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/50899047"
              isExternal
            >
              Ir al Sitio
            </Text>
            <Text
              fontSize={"md"}
              fontWeight={400}
              color={useColorModeValue("gray.600", "Black")}
            >
              o
            </Text>
            <EmailIcon
              w={12}
              h={12}
              color={useColorModeValue("Black", "Black")}
            />
          </Stack>
        </Box>
      </Stack>
    </Stack>
  );
}
