import {
  Flex,
  Heading,
  Image,
  Stack,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Countdown from "../../components/countdown/countdown";
import FadeInText from "../../components/fadeInText/fadeInText";

const targetDate: Date = new Date("2022-11-25T18:00:00");
// const text: string = `El destino nos puso en el mismo camino y ahora caminaremos juntos hasta el final`;
const text1: string = `Con la bendición de Dios y de nuestros padres
Jorge González Ramos y Maribel Robles García
Raúl Arámburo Páez y Ma. Guadalupe Jiménez Rueda
Tenemos el honor de invitarlos a la celebración de nuestro matrimonio`;

export default function Landing() {
  return (
    <Stack
      direction={"column"}
      align={"center"}
      justify={"center"}
      overflow={"hidden"}
      id="about-us"
    >
      {/* <SplitScreen /> */}
      <Flex
        rounded={"lg"}
        pos={"relative"}
        width={{ base: "150vw", md: "100vw" }}
        justify={"center"}
      >
        <Image alt="Itzel y Alejandro" src={"/pictures/Portadav5_low.png"} />
      </Flex>

      <FadeInText text={text1}></FadeInText>

      <Heading>
        <Text
          fontSize={{ base: "4xl", md: "5xl" }}
          fontWeight={400}
          color={useColorModeValue("gray.600", "white")}
        >
          Save the Date
        </Text>
      </Heading>
      <Countdown date={targetDate}></Countdown>
    </Stack>
  );
}
